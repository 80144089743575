import * as React from "react";
import "./BecomeCreator.scss";
import { useLocalize } from "../../../../redux/actions/localizeAction";
import LazyImage from "../../../../libs/lazy-load/LazyImage";
import FadeIn from "../../../fadein/FadeIn";
import { MediaDataType } from "../../../../utils";

export interface BecomeCreatorDataType {
  title: string;
  description: string;
  background: MediaDataType;
  cards: {
    image: MediaDataType;
    description: string;
  }[];
}

export interface BecomeCreatorProps {}

const BecomeCreator: React.FC<BecomeCreatorProps> = () => {
  const { data } = useLocalize<BecomeCreatorDataType>("contentCreator.becomeCreator");

  return (
    <div className="com-become-creator">
      <LazyImage className="com-become-creator__background desktop" src={data?.background.url || ""} />
      <FadeIn>
        <div className="com-become-creator__title">{data?.title || ""}</div>
      </FadeIn>
      <FadeIn>
        <div className="com-become-creator__description">{data?.description || ""}</div>
      </FadeIn>
      <FadeIn>
        <div className="com-become-creator__card-container">
          {data &&
            data?.cards.map((card, index) => {
              return (
                <div className="com-become-creator__card" key={index}>
                  <LazyImage className="com-become-creator__image" src={card?.image.url || ""} />
                  <div className="com-become-creator__card-description">{card?.description || ""}</div>
                </div>
              );
            })}
        </div>
      </FadeIn>
    </div>
  );
};

export default BecomeCreator;
