export const ABOUT_QUERY = `{
    about {
      about {
        title
        description
        image {
          url
        }
      }
    }
  }
`;
