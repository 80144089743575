import { createStore, applyMiddleware } from "redux";
import rootReducers from "./reducers";
import thunk from "redux-thunk";
import { LocalizeAction } from "./reducers/localizeReducer";
import { SystemAction } from "./reducers/systemReducer";
import { ErrorAction } from "./reducers/errorReducer";
import { NotifyAction } from "./reducers/notifyReducer";

// const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

// const store = createStore(rootReducers, /* preloadedState, */ composeEnhancers(applyMiddleware(thunk)));

export type StoreType = ReturnType<typeof rootReducers>;

const store = createStore(rootReducers, applyMiddleware(thunk));

export default store;

const dispatch = store.dispatch;
export { dispatch };

type ActionBaseType = ErrorAction | LocalizeAction | SystemAction | NotifyAction;

export interface IBaseAction {
  type: ActionBaseType;
}
