export const GAME_LIST_QUERY = `
{
  games{
    title
    icon{url}
    genre
    description
    link
    rating
  }
  moreGame{
    title
    background{url}
  }
}
`;
