import * as React from "react";
import "./MapCard.scss";
import LazyImage from "../../../../../libs/lazy-load/LazyImage";

export interface MapCardProps {
  name: string;
  description: string;
  image: SImage;
  isMobile?: boolean;
  layout: SImage | undefined;
}

const MapCard: React.FC<MapCardProps> = (props) => {
  let result;

  if (props.isMobile) {
    result = (
      <div className="com-map-card">
        <div className="card-image-mobile">
          <LazyImage className="background mobile" src={props.image?.url} />
          <div className="name-mobile">{props.name}</div>
        </div>
        <p className="description-mobile">{props.description}</p>
      </div>
    );
  } else {
    result = (
      <div className="com-map-card">
        <LazyImage className="background" src={props.layout?.url || ""} />
        <p className="name">{props.name}</p>
        <p className="description">{props.description}</p>
        <LazyImage src={props.image?.url} alt={props.name + " image"} className="card-image" />
      </div>
    );
  }

  return result;
};

export default MapCard;
