import { motion } from "framer-motion";
import "intersection-observer";
import * as React from "react";
import { useEffect, useRef } from "react";
import "./FadeIn.scss";

export interface FadeInProps {
  fromBelow?: boolean;
}

const FadeIn: React.FC<FadeInProps> = (props) => {
  const fadeRef = useRef<HTMLDivElement>(null);
  const [isVisible, setVisible] = React.useState(false);
  const posY = props.fromBelow ? 80 : -80;

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisible(entry.isIntersecting);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    const ref = fadeRef.current;

    if (ref) observer.observe(ref as Element);

    return () => {
      if (ref) observer.unobserve(ref as Element);
    };
  }, [fadeRef]);

  return (
    <motion.span className="com-fade-in" ref={fadeRef} animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : posY }} transition={{ duration: 0.5 }}>
      {props.children}
    </motion.span>
  );
};

export default FadeIn;
