import GsapSlider from "@webfac/gslider/lib/GsapSlider";
import { GSlider } from "@webfac/gslider/lib/GSlider";
import { debounce } from "lodash-es";
import * as React from "react";
import { useMemo, useState } from "react";
import { ReactComponent as Arrow } from "../../../../assets/images/action/arrow-point-to-right.svg";
import { useLocalize } from "../../../../redux/actions/localizeAction";
import FadeIn from "../../../fadein/FadeIn";
import { CharactersDataType } from "../Characters";
import CharacterCard from "./charactercards/CharacterCard";
import "./CharacterSlide.scss";

export interface CharacterSlideProps {}

const CharacterSlide: React.FC<CharacterSlideProps> = () => {
  const { data } = useLocalize<CharactersDataType>("character");

  const [selected, setSelected] = useState(0);
  const [slider, setSlider] = useState<GsapSlider>();
  const [slideDirection, setSlideDirection] = useState<"next" | "prev">("next");
  const [isSliding, setIsSliding] = useState(false);

  const handleSlideChange = useMemo(
    () =>
      debounce((slider: GsapSlider) => {
        const totalSlide = slider.totalSlide;
        const toSlide = slider.selectedSlide;
        setIsSliding(false);
        setSelected((s) => {
          if (s > toSlide) Math.abs(s - toSlide) > totalSlide / 2 ? setSlideDirection("prev") : setSlideDirection("next");
          if (s < toSlide) Math.abs(s - toSlide) > totalSlide / 2 ? setSlideDirection("next") : setSlideDirection("prev");
          return toSlide;
        });
      }, 500),
    []
  );

  return (
    <div className="com-character-slide">
      <FadeIn>
        <div className="header">
          <div className="title-container">
            <p className="title">Characters</p>
          </div>
          <p className="description">Meet all characters in the Gangstar series</p>
        </div>
      </FadeIn>
      <div className="slider-container">
        {data?.characters?.[selected] && !isSliding && (
          <div className="overlay-card" key={`${data?.characters?.[selected]?.id}`}>
            <CharacterCard {...data?.characters?.[selected]} id={selected} isOverlay direction={slideDirection} />
          </div>
        )}
        <GSlider classes={{ root: "main-slider", slide: "slide" }} sliderRef={setSlider} options={{ align: "center" }} on={{ slideChange: (slider) => handleSlideChange(slider) }}>
          {data?.characters.map((character, index) => (
            <CharacterCard className={index === selected ? "selected-slide" : ""} {...character} id={index} key={index} onClick={() => slider?.slideTo(index)} />
          ))}
        </GSlider>
      </div>
      <Arrow className="arrow" onClick={() => slider?.slideNext()} />
      <Arrow className="arrow left" onClick={() => slider?.slidePrev()} />
    </div>
  );
};

export default CharacterSlide;
