import { faPlay, faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useLocalize } from "../../../../redux/actions/localizeAction";
import { useNotify } from "../../../../redux/actions/notifyAction";
import { useResponsive } from "../../../../redux/actions/systemAction";
import "./Video.scss";

export interface VideoDataType {
  videoUrl: string;
  storeLeftIcon: SImage;
  storeLeftUrl: string;
  storeRightIcon: SImage;
  storeRightUrl: string;
}

export interface VideoProps {}

const Video: React.FC<VideoProps> = () => {
  const [videoRef, setVideoRef] = useState<HTMLVideoElement | null>();
  const [showPlayButton, setShowPlayButton] = useState(true);
  const [isMute, setMute] = useState<boolean>(true);
  const ageGate = useNotify("ageGate");
  const mobile = useResponsive("mobile");

  const { data } = useLocalize<VideoDataType>("home.video");

  const handleSoundControl = () => setMute(!isMute);

  useEffect(() => {
    if (videoRef) {
      if (mobile || !ageGate) {
        videoRef.pause();
      } else {
        videoRef.play();
      }
    }
  }, [ageGate, videoRef, mobile]);

  return (
    <div className="com-video">
      <div className="sound-controller">
        <FontAwesomeIcon onClick={handleSoundControl} className={"sound-on" + (isMute ? " hidden" : "")} icon={faVolumeUp}></FontAwesomeIcon>
        <FontAwesomeIcon onClick={handleSoundControl} className={"sound-off" + (isMute ? "" : " hidden")} icon={faVolumeMute}></FontAwesomeIcon>
      </div>
      <div className="video-container">
        <FontAwesomeIcon
          className={classNames("play-button", { show: showPlayButton })}
          onClick={() => {
            videoRef?.play();
            setShowPlayButton(false);
          }}
          icon={faPlay}
        />
        <video ref={setVideoRef} className="top-video" loop muted={isMute} autoPlay playsInline src={data?.videoUrl} />
      </div>
      <div className="tryit">
        <div className="container">
          <p>TRY IT NOW</p>
          <div className="store-icon">
            <a href={data?.storeLeftUrl} target="_blank" rel="noopener noreferrer">
              <img src={data?.storeLeftIcon?.url} alt="" />
            </a>
            <a href={data?.storeRightUrl} target="_blank" rel="noopener noreferrer">
              <img src={data?.storeRightIcon?.url} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
