import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { notifyAction_Set, useNotify } from "../../redux/actions/notifyAction";

export const useAgeGate = () => {
  const notifyPass = useNotify("ageGate");
  const alreadyPass = localStorage.getItem("ageGate") === "pass";
  const isPass = alreadyPass || notifyPass;
  const dispatch = useDispatch();

  const [Elm, setElm] = useState<HTMLElement | null>();

  useEffect(() => {
    isPass && dispatch(notifyAction_Set("ageGate", true));
  }, [isPass, dispatch]);

  useEffect(() => {
    if (Elm) {
      if (isPass) {
        Elm.style.overflow = "";
        Elm.style.height = "";
      } else {
        Elm.style.overflow = "hidden";
        Elm.style.height = "100vh";
      }
    }
  }, [isPass, Elm, dispatch]);

  return { isPass, setElm };
};
