import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HOME_QUERY } from "../../../query/home";
import { useLocalize } from "../../../redux/actions/localizeAction";
import { signalScrollBack } from "../../../redux/actions/navAction";
import { useSystem } from "../../../redux/actions/systemAction";
import { ViewMode } from "../../../redux/actions/viewAction";
import { StoreState } from "../../../redux/reducers";
import { BackTo, INavState } from "../../../redux/reducers/navReducer";
import { IViewState } from "../../../redux/reducers/viewReducer";
import Action from "../../action/Action";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import Loading from "../../loading/Loading";
import Signup from "../../signup/Signup";
import Creator from "./creator/Creator";
import FeedSection from "./feedsection/feed-section";
import "./Home.scss";
import NewsSection from "./news-section/NewsSection";
import Video from "./video/Video";

const Home: React.FC = () => {
  const nav = useSelector<StoreState, INavState>((state) => state.mobileNewsNav);
  const view = useSelector<StoreState, IViewState>((state) => state.homeView);

  const popupRef = React.createRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const { data } = useLocalize("home", { method: "POST", postData: { query: HOME_QUERY }, api: "/graphql", selector: "data.home" });

  useSystem();

  useEffect(() => {
    if (nav.needScrollBack && nav.backTo === BackTo.HOME) {
      document.getElementById("scroll-menu-header")?.scrollIntoView();
      dispatch(signalScrollBack(false));
    }
  }, [nav.needScrollBack, dispatch, nav.backTo]);

  //Active/deactive view news popup
  useEffect(() => {
    if (popupRef.current) {
      if (view.mode === ViewMode.LARGE) {
        popupRef.current.style.display = "block";
        popupRef.current.scrollIntoView();
        window.scrollBy(0, -150);
      } else popupRef.current.style.display = "none";
    }
  });

  return (
    <div className="com-home">
      <Header />
      {data ? (
        <>
          <Video />
          <Action />
          <Creator />
          <NewsSection />
          <FeedSection />
          <Signup />
          <Footer />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Home;
