export const CHARACTER_SLIDE_QUERY = `
{
  character {
    title
    description
    characters {
      id
      image {
        url
      }
      nick
      name
      title
      skill
      weapon
      wanted
      quote
      description
      background
      event
      trivia
      images {
        url
      }
    }
  }
}`;
