import * as React from "react";
import "./Synopsis.scss";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import LazyImage from "../../../libs/lazy-load/LazyImage";
import { useLocalize } from "../../../redux/actions/localizeAction";
import Loading from "../../loading/Loading";
import { ABOUT_QUERY } from "../../../query/about";
import DOMPurify from "dompurify";

export interface SynopsisProps {}

export interface SynopsisDataType {
  title: string;
  description: string;
  image: SImage;
}

const Synopsis: React.FC<SynopsisProps> = () => {
  const sanitizer = DOMPurify.sanitize;
  const { data } = useLocalize<SynopsisDataType>("about", { method: "POST", postData: { query: ABOUT_QUERY }, api: "/graphql", selector: "data.about.about" });

  return (
    <div className="com-synopsis-page">
      <Header />
      <div className="container">
        {data ? (
          <>
            <div className="story-container">
              <p className="title">{data.title}</p>
              <div
                className="text-content"
                dangerouslySetInnerHTML={{
                  __html: sanitizer(data.description || ""),
                }}
              />
            </div>
            <LazyImage className="image" src={data.image?.url} />
          </>
        ) : (
          <Loading />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Synopsis;
