import "./LazyLoad.scss";
import React, { useState, useEffect, useRef } from "react";
import { LazyType } from "./LazyLoad";
// import { API } from "../../api";
import useIntersection from "../utils/useIntersection";

export interface LazyImageProps {
  className?: string;
  preview?: JSX.Element;
  alt?: string;
  src: string;
  loadType?: LazyType;
  load?: boolean;
  onLoaded?: () => void;
}

const LazyImage = React.forwardRef<any, React.PropsWithChildren<LazyImageProps> & React.DOMAttributes<HTMLImageElement>>((props, ref) => {
  let { className, preview, src, loadType, load, onLoaded, ...others } = props;
  loadType = loadType || "inview"; // default props

  const viewRef = useRef<HTMLImageElement | null>(null);
  const { inview } = useIntersection(loadType === "inview" ? viewRef : null, { once: true });

  let lazyload = true;
  if (loadType === "inview") lazyload = inview;
  else if (loadType === "manual") lazyload = load || false;

  const { loading, src: imgsrc } = useLazyImage(src, lazyload);

  useEffect(() => {
    if (!loading) onLoaded?.();
  }, [loading, onLoaded]);

  const styles = () => {
    return {
      transition: "filter 0.5s linear",
      filter: `${loading ? "blur(30px)" : ""}`,
      transform: `${loading ? "scale(1.1)" : ""}`,
    };
  };

  return loading && props.preview ? (
    React.cloneElement(props.preview, {
      className: className,
      ref: viewRef,
    })
  ) : (
    <img
      ref={(img) => {
        viewRef.current = img;
        ref && (typeof ref === "function" ? ref(img) : ((ref as React.MutableRefObject<any>).current = img));
      }}
      className={`lazy-image ${className || ""} lazy-image--${loading ? "loading" : "loaded"}`}
      src={imgsrc}
      alt={props.alt}
      style={styles()}
      {...others}
    />
  );
});

export default LazyImage;

const useLazyImage = (imgSource: string, executing: boolean = true) => {
  const [loading, setLoading] = useState(true);
  const loaded = useRef(false);

  useEffect(() => {
    setLoading(true);
    loaded.current = false;
  }, [imgSource]);

  executing = executing && !loaded.current;
  useEffect(() => {
    if (executing) {
      setLoading(true);
      const img = new Image();
      img.onload = () => {
        loaded.current = true;
        setLoading(false);
      };
      img.src = imgSource;

      return () => {
        img.onload = null;
      };
    }
  }, [imgSource, executing]);

  //TODO: Use thumbnail when ready from BE
  const src = !executing && !loaded.current ? "" : imgSource;

  return { src, loading };
};

export { useLazyImage };
