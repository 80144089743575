import * as React from "react";
import "./CommunityFeed.scss";
import LiveFeed from "../../../livefeed/LiveFeed";
import FadeIn from "../../../fadein/FadeIn";
import { useLocalize } from "../../../../redux/actions/localizeAction";

const CommunityFeed: React.FC = () => {
  const { data } = useLocalize("community");

  return (
    <div className="com-community-feed">
      <FadeIn>
        <div className="com-community-feed__header">
          <p className="com-community-feed__title">{data?.feedTitle || "STORIES"}</p>
        </div>
        <LiveFeed />
      </FadeIn>
    </div>
  );
};

export default CommunityFeed;
