import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useAgeGate } from "../../libs/hooks/useAgeGate";
import { useLanguage } from "../../redux/actions/localizeAction";
import { useSystem } from "../../redux/actions/systemAction";
import { RECAPTCHA_SITEKEY } from "../../utils";
import useReCaptcha3 from "../../utils/recaptcha/ReCaptchaV3";
import AgeGate from "../agegate/AgeGate";
import Characters from "../views/characters/Characters";
import Community from "../views/community/Community";
import ContentCreator from "../views/contentcreator/ContentCreator";
import CookiePolicy from "../views/cookie/CookiePolicy";
import Home from "../views/home/Home";
import InnerNews from "../views/innernews/InnerNews";
import LegalNotice from "../views/legalnotices/LegalNotice";
import CityMap from "../views/map/CityMap";
import Synopsis from "../views/synopsis/Synopsis";
import "./App.scss";

const ScrollToTop: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};

const App: React.FC = () => {
  // recaptcha load
  useReCaptcha3(RECAPTCHA_SITEKEY);
  useLanguage();
  useSystem();
  const { isPass, setElm } = useAgeGate();

  return (
    <Router basename={process.env.PUBLIC_URL}>
      {!isPass && <AgeGate />}
      <ScrollToTop />
      <div ref={setElm} className="com-app">
        <Helmet>
          <title>Gangstar Vegas | Welcome to the City of Sin!</title>
          <meta name="description" content="Download Gangstar Vegas today! A massive mobile open game world full of gang wars, theft, vice, auto racing, sniper action, clan conspiracies and more!" />
        </Helmet>
        <Switch>
          <Route path="/community" exact>
            <Community />
          </Route>
          <Route path="/news/" exact>
            <Community />
          </Route>
          <Route path="/news/:newsID?" exact>
            <InnerNews />
          </Route>
          <Route path="/map" exact>
            <CityMap />
          </Route>
          <Route path="/characters" exact>
            <Characters />
          </Route>
          <Route path="/about" exact>
            <Synopsis />
          </Route>
          <Route path="/legal" exact>
            <LegalNotice />
          </Route>
          <Route path="/cookie" exact>
            <CookiePolicy />
          </Route>
          <Route path="/content-creator" exact>
            <ContentCreator />
          </Route>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
