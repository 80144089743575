import { combineReducers } from "redux";
import { navReducer } from "./navReducer";
import { createViewReducer } from "./viewReducer";
import formReducer from "./formReducer";
import { createFetchReducer } from "./fetchReducer";
import { TriggerPoint } from "../../utils";
import localizeReducer from "./localizeReducer";
import systemReducer from "./systemReducer";
import errorReducer from "./errorReducer";
import notifyReducer from "./notifyReducer";

const rootReducers = combineReducers({
  error: errorReducer,
  liveFeedView: createViewReducer(TriggerPoint.FEED),
  newsView: createViewReducer(TriggerPoint.NEWS),
  homeView: createViewReducer(TriggerPoint.HOME),
  characterView: createViewReducer(TriggerPoint.CHARACTERS),
  mobileNewsNav: navReducer,
  formSubmit: formReducer,
  // newsData: createFetchReducer(TriggerPoint.NEWS),
  feedData: createFetchReducer(TriggerPoint.FEED),
  // mapData: createFetchReducer(TriggerPoint.MAP),
  // characterData: createFetchReducer(TriggerPoint.CHARACTERS),
  // gamesData: createFetchReducer(TriggerPoint.GAMES),
  notify: notifyReducer,
  localize: localizeReducer,
  system: systemReducer,
});

export type StoreState = ReturnType<typeof rootReducers>;

export default rootReducers;
