import * as React from "react";
import "./CityMap.scss";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import InteractiveMap from "./interactivemap/InteractiveMap";
import { useLocalize } from "../../../redux/actions/localizeAction";
import { MAP_QUERY } from "./map.query";

export interface MapProps {}

const CityMap: React.FC<MapProps> = () => {
  useLocalize("map", { method: "POST", postData: { query: MAP_QUERY }, api: "/graphql", selector: "data.map" });

  return (
    <div className="com-city-map">
      <Header />
      <InteractiveMap />
      <Footer />
    </div>
  );
};

export default CityMap;
