export const MAP_QUERY = `
{
  map {
    map {
      title
      description
      baseMap { url }
      cardLayout { url }
      location {
        name
        description
        image { url }
      }
    }
  }
}
`;
