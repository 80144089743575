import Axios, { AxiosResponse, AxiosRequestConfig, Canceler } from "axios";
import * as Form from "./formApi";
import * as Feed from "./feedApi";
import store from "../redux";
import { ErrorAction_Set, ErrorType } from "../redux/actions/errorAction";
import { ErrorAction } from "../redux/reducers/errorReducer";

export const API_BASE_URL = window.APP_API_URL;

export const SUB_MANAGER_URL = window.SUB_MANAGER_URL;
export const SNS_MANAGER_URL = window.SNS_MANAGER_URL;

const axiosInstance = Axios.create({
  baseURL: API_BASE_URL,
  responseType: "json",
});

export { axiosInstance as axios };

const _requestCache: { [key: string]: { start: number; token: Canceler } } = {};
const checkRequestCache = (config: AxiosRequestConfig, cancelToken?: Canceler) => {
  if (!config) return;

  const url = config.url?.replace(config.baseURL!, "/");
  const key = `${url}&${config.method}&${JSON.stringify(config.params)}${JSON.stringify(config.data)}`;

  if (key in _requestCache) {
    if (cancelToken && Date.now() - _requestCache[key].start < 200) {
      cancelToken();
    } else {
      delete _requestCache[key];
    }
  } else {
    if (cancelToken) _requestCache[key] = { start: Date.now(), token: cancelToken };
  }
};

const handleResponse = (response: AxiosResponse<any>): AxiosResponse<any> | Promise<AxiosResponse<any>> => {
  checkRequestCache(response.config);
  if (response.status !== 200) {
    const error = response.data.error || response.data.err;
    if (error) {
      store.dispatch<ErrorAction_Set>({
        type: ErrorAction.ERROR,
        payload: {
          name: response.config.name ? response.config.name : "unnamed",
          error: {
            errorType: ErrorType.APP_ERROR,
            info: error,
          },
        },
      });
      return Promise.resolve({ ...response, data: { ...response.data, error: { errorType: ErrorType.APP_ERROR, info: error } } }); // resolve with error detail
    }
  }
  return response;
};

const handleError = (error: any) => {
  checkRequestCache(error.config);

  if (Axios.isCancel(error)) {
    return new Promise(() => {});
  }

  // dispatch connection error
  let errorType, errorCode, info;
  if (error.response && error.response.status) {
    // check if backend responecode != 200
    errorType = ErrorType.APP_ERROR;
    errorCode = error.response.status;
    info = error.response.data;
  } else {
    errorType = ErrorType.CONNECTION_ERROR;
    info = error;
  }
  store.dispatch<ErrorAction_Set>({
    type: ErrorAction.ERROR,
    payload: {
      name: error.config.name ? error.config.name : "unnamed",
      error: {
        errorType,
        errorCode,
        info,
      },
    },
  });

  return { ...error?.response, data: { ...error?.response?.data, error: { errorType, errorCode, info } } }; // resolve with error detail
};

const handleRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  // edit/inject data for request here
  // language param inject
  config.params = config.params || {};
  config.params["lang"] = localStorage.getItem("language") || "en";

  // keep cancel token at end
  config.cancelToken = new Axios.CancelToken((c) => {
    checkRequestCache(config, c);
  });
  return config;
};

// axios interceptor
axiosInstance.interceptors.response.use(handleResponse, handleError);
axiosInstance.interceptors.request.use(handleRequest);

/**
 * return the final url with s3 base
 * @param param param append
 */

const API = {
  Form: Form,
  Feed: Feed,
};

export { API };

// extend axios config
declare module "axios" {
  export interface AxiosRequestConfig {
    name?: string;
  }
}

export type ApiErrorType = {
  error: boolean;
  detail: {
    errorType: ErrorType;
    errorCode: number;
    info: any;
  };
};

// helpfull api
/**
 * send get request to sever
 * @param url server url, if this is relative url, it will combine with base url set by API_BASE_URL
 * @param config request config, can set name in config use to getback error if need
 */
const get = <T = any>(url: string, config?: AxiosRequestConfig | undefined) => {
  return axiosInstance.get<T>(url, config);
};

/**
 * send post request to sever
 * @param url server url, if this is relative url, it will combine with base url set by API_BASE_URL
 * @param data request data
 * @param config request config, , can set name in config use to getback error if need
 */
const post = <T = any>(url: string, data?: any, config?: AxiosRequestConfig | undefined) => {
  return axiosInstance.post<T>(url, data, config);
};

/**
 * send put request to sever
 * @param url server url, if this is relative url, it will combine with base url set by API_BASE_URL
 * @param data request data
 * @param config request config, can set name in config use to getback error if need
 */
const put = <T = any>(url: string, data?: any, config?: AxiosRequestConfig | undefined) => {
  return axiosInstance.put<T>(url, data, config);
};

/**
 * send delete request to sever
 * @param url server url, if this is relative url, it will combine with base url set by API_BASE_URL
 * @param name request name, use to getback error if need
 * @param config request config
 */
const _delete = <T = any>(url: string, config?: AxiosRequestConfig | undefined) => {
  return axiosInstance.delete<T>(url, config);
};

/**
 * cancel fetching request
 * @param url server url, used to combine with config method and params to identify request
 * @param config request config
 */
const cancel = (key: string) => {
  if (key in _requestCache) {
    _requestCache[key].token();
  }
};

/**
 * return the final url with base
 * @param param param append
 */
const url = (param: string) => {
  return `${API_BASE_URL}${param}`;
};

const api = {
  get,
  post,
  put,
  delete: _delete,
  url,
  cancel,
};

export default api;
