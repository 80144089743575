import * as React from "react";
import "./Benefit.scss";
import FadeIn from "../../../fadein/FadeIn";
import { useLocalize } from "../../../../redux/actions/localizeAction";
import LazyImage from "../../../../libs/lazy-load/LazyImage";
import { MediaDataType } from "../../../../utils";

export interface BenefitDataType {
  title: string;
  description: string;
  background: MediaDataType;
  cards: { image: MediaDataType; description: string }[];
}

export interface BenefitProps {}

const Benefit: React.FC<BenefitProps> = () => {
  const { data } = useLocalize<BenefitDataType>("contentCreator.benefit");

  return (
    <div className="com-benefit">
      <LazyImage className="com-benefit__background desktop" src={data?.background?.url || ""} />
      <FadeIn>
        <div className="com-benefit__title">{data?.title || ""}</div>
        <div className="com-benefit__description">{data?.description || ""}</div>
      </FadeIn>
      <FadeIn>
        <div className="com-benefit__image-row">
          {data &&
            data.cards?.map((card, index) => {
              return (
                <div className="com-benefit__card" key={index}>
                  <LazyImage className="com-benefit__image" src={card.image.url} alt="" />
                  <div className="com-benefit__text">{card.description}</div>
                </div>
              );
            })}
        </div>
      </FadeIn>
    </div>
  );
};

export default Benefit;
