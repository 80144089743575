import classNames from "classnames";
import { Variants } from "framer-motion";
import { motion } from "framer-motion";
import * as React from "react";
import LazyImage from "../../../../../libs/lazy-load/LazyImage";
import "./CharacterCard.scss";

export interface CharacterCardDataType {
  id: number;
  image: SImage;
  nick: string;
  name: string;
  title: string;
  skill: string;
  weapon: string;
  wanted: string;
  quote: string;
  description: string;
  background: string;
  event: string;
  trivia: string;
  images: SImage[];
}

export interface CharacterCardProps {
  direction?: "next" | "prev";
  isOverlay?: boolean;
  className?: string;
  onClick?: () => void;
}

const CharacterCard: React.FC<CharacterCardDataType & CharacterCardProps> = (props) => {
  const parent: Variants = {
    show: { transition: { staggerChildren: 0.1 } },
  };

  const card = {
    hide: { x: props.direction === "next" ? "-15vw" : "15vw", opacity: 0 },
    show: { x: 0, opacity: 1, transition: { duration: 0.3, ease: "easeInOut" } },
  };

  const cardImage = {
    ...card,
    show: { x: 0, opacity: 1, transition: { duration: 0.1, ease: "easeInOut" } },
  };

  return (
    <div className={classNames("com-character-card", props.className, { "com-character-card--overlay": props.isOverlay })} onClick={() => props.onClick?.()}>
      <div className="com-character-card__inner">
        {props.isOverlay && (
          <motion.div className="com-character-card__front" variants={parent} initial="hide" animate="show">
            <motion.div className="com-character-card__image" variants={cardImage}>
              <LazyImage src={props.image?.url} alt={props.name + " image"} />
            </motion.div>
            <motion.p className="com-character-card__nick-name" variants={card}>
              {props.nick}
            </motion.p>
            <motion.p className="com-character-card__full-name" variants={card}>
              {props.name}
            </motion.p>
            <motion.p className="com-character-card__title" variants={card}>
              {props.title}
            </motion.p>
            <motion.p className="com-character-card__description" variants={card}>
              {props.description}
            </motion.p>
          </motion.div>
        )}
        <div className="com-character-card__back">{props.isOverlay ? <img src={props.image?.url} alt={props.name + " image"} /> : <LazyImage src={props.image?.url} alt={props.name + " image"} />}</div>
      </div>
    </div>
  );
};

export default CharacterCard;
