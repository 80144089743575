import * as React from "react";
import "./Character.scss";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import CharacterSlide from "./characterslide/CharacterSlide";
import { CHARACTER_SLIDE_QUERY } from "../../../query/character";
import { useLocalize } from "../../../redux/actions/localizeAction";
import { CharacterCardDataType } from "./characterslide/charactercards/CharacterCard";

export interface CharactersDataType {
  title: string;
  description: string;
  characters: CharacterCardDataType[];
}

export interface CharactersProps {}

const Characters: React.FC<CharactersProps> = () => {
  useLocalize("character", { method: "POST", postData: { query: CHARACTER_SLIDE_QUERY }, api: "/graphql", selector: "data.character" });

  return (
    <div className="com-characters">
      <Header />
      <CharacterSlide />
      <Footer />
    </div>
  );
};

export default Characters;
