export const FEED_QUERY = `
{
  liveFeedSection{
    allPostText
    discord
    facebook
    instagram
    youtube
  }
  liveFeedStories(sort: "id:DESC"){
    title
    link
    image{url}
  }
}
`;
