import * as React from "react";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import GameIcon from "../../assets/images/header/logo-gsv.png";
import { notifyAction_Set } from "../../redux/actions/notifyAction";
import "./AgeGate.scss";

export interface AgeGateProps {}

const AgeGate: React.FC<AgeGateProps> = () => {
  const [error, setError] = useState({ age: false, empty: true });
  const dispatch = useDispatch();

  const handleAgeValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.search(/[0-9]*$/g)) e.currentTarget.value = "";
    if (e.currentTarget.value === "") setError({ empty: true, age: false });
    else if (parseInt(e.currentTarget.value) < 18) setError({ age: true, empty: false });
    else setError({ empty: false, age: false });
  };

  const disableAgeGate = useCallback(() => {
    localStorage.setItem("ageGate", "pass");
    dispatch(notifyAction_Set("ageGate", true));
  }, [dispatch]);

  const isValid = !error.empty && !error.age;

  return (
    <div className="com-age-gate" onKeyDown={(e) => e.keyCode === 13 && isValid && disableAgeGate()}>
      <div className="container">
        <img src={GameIcon} alt="Gangstar Vegas" className="game-icon" />
        <label htmlFor="age-text-box">CONFIRM YOUR AGE</label>
        <input autoComplete="off" id="age-text-box" type="text" className="age-text-box" maxLength={2} onChange={(e) => handleAgeValidation(e)} />
        <div className="text-warning">
          {error.empty && <span className="warning-message">You must be at least 18 to enter this site</span>}
          {error.age && (
            <div className="error-message">
              <p>We’re sorry but Gangstar Vegas is for mature audiences!</p>
              <p>We’ll be waiting for you to come back when you’re 18!</p>
            </div>
          )}
        </div>
        <button onClick={() => isValid && disableAgeGate()} className="confirm-button">
          CONFIRM
        </button>
      </div>
    </div>
  );
};

export default AgeGate;
