import { ReactComponent as LoadingSpin } from "../../assets/images/loading.svg";

import "./Loading.scss";

interface LoadingProps {}

const Loading: React.FC<LoadingProps> = () => {
  return <LoadingSpin className="com-loading" />;
};

export default Loading;
