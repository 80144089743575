import EActionTypes, { IBaseAction } from "./actionTypes";
import { TriggerPoint, formatDate } from "../../utils";
import { ThunkAction } from "redux-thunk";
import { StoreState } from "../reducers";
import { API } from "../../api";

type FetchAPI = {
  mapData: Function;
  fetchFromAPI: Function;
};

const generateFetchObject = (location: TriggerPoint) => {
  let result: FetchAPI = {
    mapData: (data: Array<any>) => {
      return data;
    },
    fetchFromAPI: () => {
      return null;
    },
  };
  switch (location) {
    // case TriggerPoint.NEWS:
    //   result.fetchFromAPI = API.News.getListNews;
    //   result.mapData = (data: Array<any>) => {
    //     return data.map((d) => {
    //       return {
    //         image: d.thumbnail,
    //         imageMedium: d.image,
    //         imageLarge: d.hotspot,
    //         date: d.post_create,
    //         title: d.post_title,
    //         id: d.post_name,
    //         description: d.short_desc,
    //         longDescription: d.post_content,
    //         youtubeLink: d.video_link,
    //         isHotSpot: d.is_hotspot,
    //         metaTitle: d.meta_title,
    //         metaDescription: d.meta_description,
    //       };
    //     });
    //   };
    //   break;
    case TriggerPoint.FEED:
      result.fetchFromAPI = API.Feed.getListFeed;
      result.mapData = (data: Array<any>) => {
        return data.map((d) => {
          return {
            id: d.id,
            link: d.link,
            imageUrl: d.images?.[0],
            videoUrl: d.videos?.[0] || "",
            text: d.description,
            footer: {
              type: d.type,
              profileName: d.profileName,
              date: formatDate(d.date),
            },
          };
        });
      };
      break;
    // case TriggerPoint.MAP:
    //   result.fetchFromAPI = API.Map.getListLocation;
    //   break;
    // case TriggerPoint.CHARACTERS:
    //   result.fetchFromAPI = API.Character.getListCharacters;
    //   break;
    // case TriggerPoint.GAMES:
    //   result.fetchFromAPI = API.Games.getListGames;
    //   break;
    default:
  }
  return result;
};

interface IFetchAction extends IBaseAction {
  type: EActionTypes;
  location: TriggerPoint;
  payload: Array<any>;
}

export type FetchAction = IFetchAction;

const fetchRequest = (location: TriggerPoint): IFetchAction => {
  return {
    type: EActionTypes.FETCH_REQUEST,
    location: location,
    payload: [],
  };
};

const fetchSuccess = (location: TriggerPoint, data: Array<any>): IFetchAction => {
  return {
    type: EActionTypes.FETCH_SUCCESS,
    location: location,
    payload: data,
  };
};

const fetchAppend = (location: TriggerPoint, data: Array<any>): IFetchAction => {
  return {
    type: EActionTypes.FETCH_APPEND,
    location: location,
    payload: data,
  };
};

const fetchFail = (location: TriggerPoint): IFetchAction => {
  return {
    type: EActionTypes.FETCH_FAILURE,
    location: location,
    payload: [],
  };
};

export const fetchData =
  (location: TriggerPoint, isAppend: boolean = false): ThunkAction<void, StoreState, undefined, FetchAction> =>
  async (dispatch) => {
    dispatch(fetchRequest(location));
    let fetch = generateFetchObject(location);
    let result = await fetch.fetchFromAPI();
    let data;
    if (result.status !== 200) {
      dispatch(fetchFail(location));
    } else {
      data = fetch.mapData(result.data, location);
      if (isAppend) dispatch(fetchAppend(location, data));
      else dispatch(fetchSuccess(location, data));
    }
  };

export const fetchFeedData =
  (type: string = "all", isAppend: boolean = false, offset: number = 0, limit: number = 5): ThunkAction<void, StoreState, undefined, FetchAction> =>
  async (dispatch) => {
    dispatch(fetchRequest(TriggerPoint.FEED));
    let fetch = generateFetchObject(TriggerPoint.FEED);
    let result = await fetch.fetchFromAPI(type, offset, limit);
    let data;
    if (result.status !== 200) {
      dispatch(fetchFail(TriggerPoint.FEED));
    } else {
      data = fetch.mapData(result.data, TriggerPoint.FEED);
      if (isAppend) {
        dispatch(fetchAppend(TriggerPoint.FEED, data));
      } else dispatch(fetchSuccess(TriggerPoint.FEED, data));
    }
  };
