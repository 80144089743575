export const NEWS_QUERY = "{newsList{NewsCard{slug title description content image{url} hotspot{url} metaTitle metaDescription date videoUrl visible}}}";

export const GenerateNewsQuery = (limit: number = -1) => `{
    articles(sort: "date:DESC", limit: ${limit}) {
      slug
      date
      title
      description
      content
      videoUrl
      image {
        url
      }
      hotspot {
        url
      }
      metaTitle
      metaDescription
    }
  }`;
