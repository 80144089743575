import * as React from "react";
import "./TopContainer.scss";
import NewsTab from "../news-tab/NewsTab";
import { useState } from "react";
import CommunityFeed from "../community-feed/CommunityFeed";
import { useLocalize } from "../../../../redux/actions/localizeAction";

export interface TopContainerProps {}

const TopContainer: React.FC<TopContainerProps> = (props) => {
  const [newsOpen, setNewsOpen] = useState<boolean>(true);
  const { data } = useLocalize("community");

  return (
    <div className="com-top-container">
      <div className="com-switch">
        <label className="com-switch__button">
          <input type="checkbox" id="switch" className="com-switch__input" onClick={() => setNewsOpen((state) => (state = !state))} />
          <span className="com-switch__slider"></span>
          <span className="com-switch__news">{data?.newsTabTitle || "NEWS"}</span>
          <span className="com-switch__live">{data?.feedTabTitle || "LIVE"}</span>
        </label>
      </div>
      <div className={`com-top-container__news${newsOpen ? "" : " hide"}`}>
        <NewsTab />
      </div>
      <div className={`com-top-container__feed${newsOpen ? " hide" : ""}`}>
        <CommunityFeed />
      </div>
    </div>
  );
};

export default TopContainer;
