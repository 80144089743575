export const communityQuery = `
{
  community{
    meta
    setting
    newsTitle
    newsTabTitle
    feedTitle
    feedTabTitle
  }
}
`;
