import * as React from "react";
import "./VideoPlayer.scss";
import { MediaType } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import { Fragment } from "react";
import LazyImage from "../../libs/lazy-load/LazyImage";
import ReactPlayer from "react-player";

export interface VideoPlayerProps {
  type: MediaType;
  url?: string;
  thumbnail?: string;
  title: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
  const Player = ReactPlayer as any;

  const generateIframe = () => {
    switch (props.type) {
      case MediaType.Youtube:
        // return <iframe title={props.title} src={"//www.youtube.com/embed/" + props.url + "?controls=0&rel=0"} width="100%" height="100%" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>;
        return <Player className="com-video-player__player" url={props.url} width="100%" height="500px" />;
      case MediaType.Facebook:
        return <Player className="com-video-player__player" url={props.url} width="100%" height="100%" />;
      case MediaType.Instagram:
        return (
          <Fragment>
            <LazyImage src={props.thumbnail || ""} />
            <iframe title={props.title} src={props.url} width="100%" height="100%" frameBorder="0" scrolling="no"></iframe>
          </Fragment>
        );
      default:
        break;
    }
  };

  return (
    <Fragment>
      <div className="com-video-player__thumbnail">
        <FontAwesomeIcon icon={faPlayCircle} size="3x" color="white" />
        <LazyImage src={props.thumbnail || ""} />
      </div>
      <div className={"com-video-player__video com-video-player--" + props.type}>{generateIframe()}</div>
    </Fragment>
  );
};

export default VideoPlayer;
