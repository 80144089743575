import * as React from "react";
import "./NewsTab.scss";
import News from "../../../news/News";
import FadeIn from "../../../fadein/FadeIn";
import { useLocalize } from "../../../../redux/actions/localizeAction";

export interface NewsTabProps {}

const NewsTab: React.FC<NewsTabProps> = () => {
  const { data } = useLocalize("community");

  return (
    <div className="com-news-tab">
      <FadeIn>
        <div className="com-news-tab__title">{data?.newsTitle || "NEWS"}</div>
        <News />
      </FadeIn>
    </div>
  );
};

export default NewsTab;
