import * as React from "react";
import "./MoreGames.scss";
import { useState, useEffect } from "react";
import { ReactComponent as LoadingSpin } from "../../assets/images/loading.svg";
import LazyImage from "../../libs/lazy-load/LazyImage";
import { GAME_LIST_QUERY } from "./games.query";
import { useLocalize } from "../../redux/actions/localizeAction";

export interface GameCardDataType {
  icon: SImage;
  title: string;
  genre: string;
  description: string;
  link: string;
  rating: number;
}

export interface GameCardProps {
  data: GameCardDataType | undefined;
}

const GameCard: React.FC<GameCardProps> = (props) => {
  const { data } = props;
  const generateStars = (rate: number) => {
    let percentage;
    if (rate > 5) rate = 5;
    percentage = (rate / 5) * 100;

    return (
      <div
        className="rating-stars"
        style={{
          background: "linear-gradient(90deg, #FABF49 " + percentage + "%, #ffffff " + (100 - percentage) + "%)",
          WebkitBackgroundClip: "text",
          color: "transparent",
        }}
      >
        ★★★★★
      </div>
    );
  };
  return (
    <div className="com-game-card">
      <a href={data?.link} className="link-to-game" target="_blank" rel="noopener noreferrer">
        <div className="brief-container">
          <LazyImage src={data?.icon.url || ""} alt="" className="game-icon" />
          <div className="text-container">
            <p className="title">{data?.title}</p>
            {data?.rating && (
              <div className="rating">
                {generateStars(data.rating)}
                <span className="rating-number">{data?.rating}</span>
              </div>
            )}
            <p className="genre">{data?.genre}</p>
          </div>
        </div>
        <p className="description">{data?.description}</p>
      </a>
    </div>
  );
};

export interface MoreGamesDataType {
  title: string;
  background: SImage;
}

export interface MoreGamesProps {}

const MoreGames: React.FC<MoreGamesProps> = (props) => {
  const [displayLoading, setDisplayLoading] = useState<boolean>(true);
  const { data: rawData } = useLocalize<{ games: GameCardDataType[]; moreGame: MoreGamesDataType }>("games", {
    method: "POST",
    postData: { query: GAME_LIST_QUERY },
    api: "/graphql",
    selector: "data",
  });
  const games = rawData?.games;
  const data = rawData?.moreGame;

  useEffect(() => {
    setDisplayLoading(!!!games);
  }, [games]);

  return (
    <div className="com-more-games">
      <LazyImage className="background" src={data?.background?.url || ""} />
      <div className="container">
        <div className="header">
          <p>{data?.title}</p>
        </div>
        <LoadingSpin className={"loading-spin" + (displayLoading ? "" : " hide")} />
        <div className="game-list">
          {games?.map((card, index) => (
            <GameCard key={index} data={card} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MoreGames;
